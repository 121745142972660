import { usePrivy } from "@privy-io/react-auth";
import { ethers } from "ethers";
import "../App.css";
import { encodeFunctionData } from "viem";
import { abiWallet } from "../config/abi.contract";

function useTicket() {
  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const { sendTransaction } = usePrivy();
  const walletContractAddress =
    process.env.REACT_APP_FRENZY_WALLET_CONTRACT_ADDRESS;

  const purchaseTournamentTicket = async (ethAmount, ticketQuantity) => {
    try {
      const value = ethers.utils.parseEther(ethAmount).toHexString();
      const data = encodeFunctionData({
        abi: abiWallet,
        functionName: "buyTicket",
        args: [ticketQuantity],
      });

      const txUiConfig = {
        header: `Purchase ${ticketQuantity} ticket(s) for tournament`,
        description: `Send ${ethAmount} ETH to owner for purchasing ${ticketQuantity} ticket(s)`,
        buttonText: `Send`,
      };

      // Construct transaction request
      const transactionRequest = {
        to: walletContractAddress,
        data: data,
        value: value,
      };

      const txReceipt = await sendTransaction(transactionRequest, txUiConfig);

      if (txReceipt.status === 1) {
        const backendResponse = await verifyTxHashForPurchaseTournamentTicket(
          txReceipt.transactionHash
        );

        if (backendResponse.success) {
          return {
            success: true,
            message: "Purchase ticket successful",
            data: backendResponse.data,
          };
        } else {
          return { success: false, message: "Verify transaction failed" };
        }
      } else {
        console.error("Transaction failed:", txReceipt);
        return { success: false, message: "Transaction failed on blockchain" };
      }
    } catch (error) {
      console.error("Lỗi khi gửi transaction:", error);
      return { success: false, message: "Send Transaction failed" };
    }
  };

  const verifyTxHashForPurchaseTournamentTicket = async (txHash) => {
    try {
      const response = await fetch(
        urlEndpoint + "/trading/purchase-tournament-ticket",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("fishAuth")}`, // Gửi JWT token trong header
          },
          body: JSON.stringify({ transactionHash: txHash }),
        }
      );
      const data = await response.json();
      console.log("Transaction Info:", data);

      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Lỗi khi gửi transactionHash về backend:", error);
      return { success: false, message: error.message };
    }
  };

  return { purchaseTournamentTicket };
}

export default useTicket;
