import { usePrivy } from "@privy-io/react-auth";
import { ethers } from "ethers";
import "../App.css";

function useWithdraw() {
  const { sendTransaction } = usePrivy();

  const handleWithdraw = async (receivedWalletAddress, ethAmount) => {
    try {
      const weiValue = ethers.utils.parseEther(ethAmount);
      const hexWeiValue = ethers.utils.hexlify(weiValue);
      const transactionRequest = {
        to: receivedWalletAddress,
        value: hexWeiValue,
      };

      return await sendTransaction(transactionRequest);
    } catch (error) {
      console.error('Lỗi khi gửi transaction:', error);
    }
  };

  return { handleWithdraw };
}

export default useWithdraw;
