import {
  addRpcUrlOverrideToChain,
  useFundWallet,
  useLinkAccount,
  usePrivy,
} from "@privy-io/react-auth";
import React, { useEffect, useRef, useState } from "react";
import { base, baseSepolia } from "viem/chains";
import useCustomLogout from "./hook/useLogout";
import usePurchase from "./hook/usePurchase";
import useTicket from "./hook/useTicket";
import useTwitter from "./hook/useTwitter";
import useWithdraw from "./hook/useWithdraw";

let baseCustomRPC;

if (process.env.REACT_APP_ENVIRONMENT === "prod") {
  baseCustomRPC = addRpcUrlOverrideToChain(
    base,
    process.env.REACT_APP_RPC_BASE_SEPOLIA
  );
} else {
  baseCustomRPC = addRpcUrlOverrideToChain(
    baseSepolia,
    process.env.REACT_APP_RPC_BASE_SEPOLIA
  );
}

const telegramBot = process.env.REACT_APP_TELEGRAM_BOT_NAME;
const telegramApp = process.env.REACT_APP_TELEGRAM_APP_NAME;

const GameComponent = () => {
  const { user, unlinkTwitter } = usePrivy();
  const { postTweet } = useTwitter();
  const { customLogout } = useCustomLogout();
  const { purchaseGold } = usePurchase();
  const { purchaseTournamentTicket } = useTicket();
  const { handleWithdraw } = useWithdraw();
  const { exportWallet } = usePrivy();
  const { updateTwitterInfo } = useTwitter();
  const { linkTwitter } = useLinkAccount({
    onSuccess: async (linkedAccount) => {
      await updateTwitterInfo(linkedAccount.twitter);
    },
  });

  const { fundWallet } = useFundWallet({
    onUserExited() {
      refreshUserData(null, true, true);
    },
  });

  const twitterSubject = user?.twitter?.subject || null;

  const [containerStyle, setContainerStyle] = useState({
    height: "100dvh",
    width: "100vw",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });

  const iframeRef = useRef(null);
  const [gameState, setGameState] = useState(() => {
    const savedState = localStorage.getItem("cocosGameState");
    return savedState ? JSON.parse(savedState) : null;
  });

  useEffect(() => {
    return () => {
      localStorage.removeItem("reauth_done");
    };
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      console.log("[React-handleMessage]", event);
      console.log(event.data.type);
      console.log(event.data);
      if (event.source === iframeRef.current?.contentWindow) {
        console.log("Received Event from GameFE:", event.data);
        localStorage.setItem("cocosGameState", JSON.stringify(event.data));
        setGameState(event.data);
        if (event.data.type === "buttonLogOutClicked") {
          cocosLogoutBtnClick(event.data.data);
        } else if (event.data.type === "buttonBuyCoinClicked") {
          cocosExchangeGoldBtnClick(event.data.data);
        } else if (event.data.type === "buttonBuyEventTicketClicked") {
          cocosBuyEventTicketBtnClick(event.data.amountETH);
        } else if (event.data.type === "buttonDepositClicked") {
          cocosDepositBtnClick(event.data.message);
        } else if (event.data.type === "buttonExportWalletClicked") {
          cocosExportWalletBtnClick(event.data.data);
        } else if (event.data.type === "buttonWithdrawClicked") {
          cocosWithdrawBtnClick(event.data.address, event.data.amountETH);
        } else if (event.data.type === "buttonLinkTwitterClicked") {
          cocosLinkTwitterBtnClick(event.data.data);
        } else if (event.data.type === "buttonUnlinkTwitterClicked") {
          cocosUnlinkTwitterBtnClick(event.data.data);
        } else if (event.data.type === "buttonShareFishClicked") {
          cocosShareFishBtnClick(event.data.data);
        } else if (event.data.type === "buttonShareOnTelegram") {
          cocosShareTelegramBtnClick(event.data.data);
        } else if (event.data.type === "OpenTelegramInvoice") {
          cocosOpenTelegramInvoice(event.data.data);
        } else {
          const authToken = localStorage.getItem("fishAuth");
          const userId = localStorage.getItem("userId");
          const beAddress = localStorage.getItem("fish_be_endpoint");
          const isTelegram =
            window.Telegram.WebView.initParams.tgWebAppData != undefined;          

          iframeRef.current.contentWindow.postMessage(
            {
              authToken: authToken,
              userId: userId,
              beAddress: beAddress,
              isTelegram: isTelegram,
              telegramBot: telegramBot,
              telegramApp: telegramApp,
            },
            "*"
          );
          console.log("[React-postMessage] token: " + authToken);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    // Restore state after iframe is loaded
    const onIframeLoad = () => {
      if (gameState && iframeRef.current) {
        iframeRef.current.contentWindow.postMessage(
          { type: "RESTORE_STATE", state: gameState },
          "*"
        );
      }
    };

    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener("load", onIframeLoad);
    }

    return () => {
      window.removeEventListener("message", handleMessage);
      if (iframe) {
        iframe.removeEventListener("load", onIframeLoad);
      }
    };
  }, [gameState]);

  const refreshDataInventory = async (data) => {
    if (iframeRef.current) {
      console.log(">>RefreshDataInventoryEvent");
      iframeRef.current.contentWindow.postMessage(
        {
          type: "refreshDataInventory",
          hasWaiting: data.hasWaiting,
          notifyMessage: data.notifyMessage,
        },
        "*"
      );
    }
  };

  const activeWaiting = async (isWaiting) => {
    if (iframeRef.current) {
      console.log(">>ActiveWaitingEvent", isWaiting);
      iframeRef.current.contentWindow.postMessage(
        { type: "activeWaiting", isWaiting: isWaiting },
        "*"
      );
    }
  };

  const refreshUserData = async (message, isSuccess, hasWaiting) => {
    if (iframeRef.current) {
      console.log(">>RefreshUserDataEvent");
      iframeRef.current.contentWindow.postMessage(
        {
          type: "refreshUserData",
          message,
          isSuccess,
          hasWaiting,
        },
        "*"
      );
    }
  };

  const getBuyTicketResult = async (message, isSuccess, hasWaiting) => {
    if (iframeRef.current) {
      console.log(">>BuyTicketResultEvent");
      iframeRef.current.contentWindow.postMessage(
        { type: "buyTicketResult", message, isSuccess, hasWaiting },
        "*"
      );
    }
  };

  const notifyReact = async (data) => {
    if (gameState && iframeRef.current) {
      console.log(">>NotifyReact");
      iframeRef.current.contentWindow.postMessage(
        {
          type: "notifyReact",
          message: data.message,
          isSuccess: data.isSuccess,
        },
        "*"
      );
    }
  };

  const cocosLogoutBtnClick = async (data) => {
    console.log("Button in Cocos was clicked!", data);
    customLogout();
  };

  const cocosExchangeGoldBtnClick = async (data) => {
    console.log("Button in Cocos was clicked!", data);
    activeWaiting(true);
    const { success, message } = await purchaseGold(data);
    activeWaiting(false);
    refreshUserData(message, success, true);
  };

  const cocosBuyEventTicketBtnClick = async (amountETH) => {
    console.log("Button in Cocos was clicked!", amountETH);
    activeWaiting(true);
    const { success, message } = await purchaseTournamentTicket(amountETH, 1);
    activeWaiting(false);
    getBuyTicketResult(message, success, true);
  };

  const cocosDepositBtnClick = async (amount) => {
    console.log("Button in Cocos was clicked!", amount);
    await fundWallet(user.wallet.address, {
      chain: baseCustomRPC,
      amount: amount.toString(),
    });
  };

  const cocosExportWalletBtnClick = (data) => {
    console.log("Button in Cocos was clicked!", data);
    exportWallet();
  };

  const cocosLinkTwitterBtnClick = async (data) => {
    console.log("Button in Cocos was clicked!", data);
    if (!user.twitter?.subject) {
      linkTwitter();
    } else {
      console.warn("Tài khoản Twitter đã được liên kết.");
    }
  };

  const cocosUnlinkTwitterBtnClick = async (data) => {
    console.log("Button in Cocos was clicked!", data);
    try {
      await unlinkTwitter(twitterSubject);
      await updateTwitterInfo(null);
      refreshDataInventory({
        notifyMessage: "Successfully unlink X",
        hasWaiting: true,
      });
    } catch (error) {
      console.error("Unlink Twitter thất bại:", error);
    }
  };

  const cocosWithdrawBtnClick = async (address, amount) => {
    console.log("Button in Cocos was clicked!", address);
    const tx = await handleWithdraw(address.trim(), amount.trim());
    if (tx && tx.status === 1) {
      refreshUserData(null, true, true);
    }
  };

  const cocosShareFishBtnClick = async (data) => {
    console.log("Button in Cocos was clicked!", data);
    await postTweet(data);
  };

  const cocosShareTelegramBtnClick = async (refCode) => {    
    const message = "FISHING FRENZY" + "\n";
    const link = "https://t.me/share/url?url=https://t.me/" +
    telegramBot + "/" +
    telegramApp + 
    "?startapp=" + refCode + "&text=" + encodeURIComponent(message);
    
    window.Telegram.WebApp.openLink(link);
  };

  const cocosOpenTelegramInvoice = async (invoiceLink) => {        
    window.Telegram.WebApp.openLink(invoiceLink);
  };

  return (
    <div style={containerStyle}>
      <iframe
        ref={iframeRef}
        src="/cocos/index.html"
        title="Cocos Game"
        style={{ width: "100%", height: "100%", border: "none" }}
      />
    </div>
  );
};

export default GameComponent;
