import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import "../App.css";
import instruction1 from "../assets/images/t1.png";
import instruction2 from "../assets/images/t2.png";
import gameImage from "../bg-game.png";
import { useGlobalContext } from "../context";
import useCustomLogin from "../hook/useLogin";
import { getPlatform } from "../utils";

function Login() {
  const { login, guestLogin, ready } = useCustomLogin();
  const { errorMessage, setErrorMessage } = useGlobalContext();
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstall, setShowInstall] = useState(true);
  const [platform, setPlatform] = useState("");
  const telegramBot = process.env.REACT_APP_TELEGRAM_BOT_NAME;
  const telegrameApp = process.env.REACT_APP_TELEGRAM_APP_NAME;

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    const platform = getPlatform();
    setPlatform(platform);

    const checkStandalone = () => {
      const isDesktopOrTelegram =
        isRunOnTelegram() ||
        window.matchMedia("(display-mode: standalone)").matches;

      console.log("isDesktopOrTelegram", isDesktopOrTelegram);

      const isMobile = !isDesktopOrTelegram;
      setShowInstall(isMobile);
    };

    checkStandalone();

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleLogin = () => {
    login();
  };

  const handleGuestLogin = () => {
    guestLogin();
  };

  const handleAddToHomeScreen = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        setDeferredPrompt(null);
      });
    } else {
      setErrorMessage("App already installed.");
    }
  };

  const handTelegramShareFriends = () => {
    const friendCode = "TONYYY";
    const message = "FISHING FRENZY" + "\n";
    const link =
      "https://t.me/share/url?url=https://t.me/" +
      telegramBot +
      "/" +
      telegrameApp +
      "?startapp=" +
      friendCode +
      "&text=" +
      encodeURIComponent(message);

    const tg = window.Telegram.WebApp;
    tg.openLink(link);
  };

  const isRunOnTelegram = () => {
    return window.Telegram.WebView.initParams.tgWebAppData !== undefined;
  };

  return ready ? (
    <div className="App">
      <header className="App-header">
        <div className="Game-container" style={{ position: "relative" }}>
          {errorMessage && (
            <div className="login-error-message">
              <p>{errorMessage}</p>
            </div>
          )}
          <img src={gameImage} alt="game" className="Game-image" />

          {isMobile && platform === "Android" ? (
            <>
              {showInstall ? (
                <div className="instruction-wrapper">
                  <div className="pop-android">
                    <div className="toptext">
                      <span>Add to home page</span>
                    </div>
                    <div className="pop2-android">
                      <div className="instruction-text-android">
                        To start playing, you need to add this website to home
                        screen
                      </div>
                    </div>
                    <div className="install-btn-wrapper">
                      <button
                        onClick={handleAddToHomeScreen}
                        className="install-button"
                      >
                        <span>Install</span>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="login-wrapper">
                  <button onClick={handleLogin} className="Play-button">
                    <span className="press-start-2p-regular">Log In</span>
                  </button>
                  <button
                    onClick={handleGuestLogin}
                    className="Play-as-guest-button"
                  >
                    <span className="press-start-2p-regular">
                      Play as Guest
                    </span>
                  </button>
                </div>
              )}
            </>
          ) : isMobile && platform !== "Android" ? (
            <>
              {showInstall ? (
                <div className="instruction-wrapper">
                  <div className="pop">
                    <div className="toptext">
                      <span>Add to home page</span>
                    </div>
                    <div className="pop2">
                      <div className="instruction-text">
                        To start playing, you need to add this website to home
                        screen
                      </div>
                      <div className="instruction-step-wrapper">
                        <div className="step-wrapper">
                          <div className="d-flex gap-2 justify-content-round">
                            <div className="d-flex flex-column justify-content-between align-items-center flex-fill">
                              <span className="sub-title">
                                Step <p className="d-inline step">1</p>
                              </span>
                              <img
                                src={instruction1}
                                className="step-image"
                                alt=""
                              />
                            </div>
                            <div className="d-flex gap-3 flex-column justify-content-round align-items-center flex-fill">
                              <span className="sub-title">
                                Step <p className="d-inline step">2</p>
                              </span>
                              <img
                                src={instruction2}
                                className="step-image"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="login-wrapper">
                  <button onClick={handleLogin} className="Play-button">
                    <span className="press-start-2p-regular">Log In</span>
                  </button>
                  <button
                    onClick={handleGuestLogin}
                    className="Play-as-guest-button"
                  >
                    <span className="press-start-2p-regular">
                      Play as Guest
                    </span>
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="login-wrapper">
              <button onClick={handleLogin} className="Play-button">
                <span className="press-start-2p-regular">Log In</span>
              </button>
              <button
                onClick={handleGuestLogin}
                className="Play-as-guest-button"
              >
                <span className="press-start-2p-regular">Play as Guest</span>
              </button>
            </div>
          )}
        </div>
      </header>
    </div>
  ) : (
    <></>
  );
}

export default Login;
