import {
  getAccessToken,
  useLogin,
  usePrivy,
  useSetWalletRecovery,
} from "@privy-io/react-auth";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../App.css";
import { useGlobalContext } from "../context";
import { getDeviceId, removeDeviceId } from "../utils";

function useCustomLogin() {
  const { ready, authenticated, logout } = usePrivy();
  const { setIsAuthenticated, setErrorMessage } = useGlobalContext();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const telegramBot = process.env.REACT_APP_TELEGRAM_BOT_NAME;
  const telegrameApp = process.env.REACT_APP_TELEGRAM_APP_NAME;

  const next = search.get("next") ? window.atob(search.get("next")) : null;
  const codeQuery = search.get("code") || "";
  const url_verifyCode = `${urlEndpoint}/reference-code/verify?code=${codeQuery}`;
  const deviceId = getDeviceId();
  const { setWalletRecovery } = useSetWalletRecovery({
    onError: async (error) => {
      handleLoginError(error);
    },
  });

  const handleGetTeleRefCode = () => {
    console.log("TELEGRAM get Refcode");
    var webAppInitData = window.Telegram.WebView.initParams.tgWebAppData;
    var webAppInitDataUnsafe =
      window.Telegram.Utils.urlParseQueryString(webAppInitData);

    for (var key in webAppInitDataUnsafe) {
      var val = webAppInitDataUnsafe[key];
      try {
        if (
          (val.substr(0, 1) === "{" && val.substr(-1) === "}") ||
          (val.substr(0, 1) === "[" && val.substr(-1) === "]")
        ) {
          webAppInitDataUnsafe[key] = JSON.parse(val);
          const refCode = window.Telegram.WebApp.initDataUnsafe.start_param;
          const teleUserID = webAppInitDataUnsafe.user.id;
          const teleUserName = webAppInitDataUnsafe.user.username;

          return { refCode, teleUserID };
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const { login } = useLogin({
    onError: async (err) => {
      console.log("Error", err);
      logout();
    },
    onOAuthLoginComplete: async (oAuthTokens) => {
      if (oAuthTokens.provider === "twitter") {
        localStorage.setItem("twitter_access_token", oAuthTokens.accessToken);
        localStorage.setItem("twitter_refresh_token", oAuthTokens.refreshToken);
      }
    },
    onComplete: async (user, linkedAccount) => {
      const privyToken = await getPrivyAccessToken();
      let url_login = `${urlEndpoint}/auth/login`;
      let refCode = null;
      let teleUserId = null;

      if (window.Telegram.WebView.initParams.tgWebAppData !== undefined) {
        const teleInfo = handleGetTeleRefCode();
        refCode = teleInfo.refCode;
        teleUserId = teleInfo.teleUserID;
      }

      console.log("Tele user Id", teleUserId);

      try {
        if (!(await alreadyHasUserOwnedRecovery(user))) {
          await setWalletRecovery();
        }
        const { data } = await axios.post(
          url_login,
          JSON.stringify({
            deviceId,
            teleUserId,
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "x-privy-token": privyToken,
            },
          }
        );

        if (refCode) {
          await axios.post(
            `${urlEndpoint}/reference-code/verify?code=${refCode}`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${data?.tokens.access.token}`,
              },
            }
          );
        }
        handleSuccessfulLogin(data, privyToken);
        removeDeviceId();
      } catch (error) {
        removeDeviceId();
        handleLoginError(error);
      }
    },
  });

  const getPrivyAccessToken = async () => {
    const accessToken = await getAccessToken();
    return accessToken;
  };

  const guestLogin = async () => {
    const url_guest_login = `${urlEndpoint}/auth/guest-login`;
    let refCode = null;
    let teleUserId = null;

    if (window.Telegram.WebView.initParams.tgWebAppData !== undefined) {
      const teleInfo = handleGetTeleRefCode();
      refCode = teleInfo.refCode;
      teleUserId = teleInfo.teleUserID;
    }
    console.log("Tele user Id", teleUserId);

    try {
      const { data } = await axios.post(
        url_guest_login,
        JSON.stringify({ deviceId, teleUserId }),
        { headers: { "Content-Type": "application/json" } }
      );

      if (refCode) {
        await axios.post(
          `${urlEndpoint}/reference-code/verify?code=${refCode}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${data?.tokens.access.token}`,
            },
          }
        );
      }
      handleSuccessfulLogin(data);
    } catch (error) {
      localStorage.setItem("isGuest", false);
      handleLoginError(error);
    }
  };

  const handleSuccessfulLogin = async (data, privyToken) => {
    localStorage.setItem("fishAuth", data?.tokens.access.token);
    localStorage.setItem("userId", data?.user.id);
    console.log("Logged in FISH_BE");
    localStorage.setItem("isGuest", data?.user.isGuest);
    setIsAuthenticated(authenticated);
    localStorage.setItem("isAuthenticated", true);
    localStorage.setItem("fish_be_endpoint", urlEndpoint);

    if (codeQuery) {
      try {
        await axios.post(
          url_verifyCode,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${data?.tokens.access.token}`,
            },
          }
        );
      } catch (error) {
        console.log("Code verification failed:", error);
      }
    }

    navigate(next ?? "/");
  };

  const alreadyHasUserOwnedRecovery = async (user) => {
    if (!user) {
      throw new Error("user is not provided");
    }
    const embeddedWallet = user.linkedAccounts.find(
      (account) =>
        account.type === "wallet" && account.walletClientType === "privy"
    );
    if (!embeddedWallet) {
      throw new Error("No embedded wallet found");
    }
    return embeddedWallet.recoveryMethod !== "privy";
  };

  const handleLoginError = async (error) => {
    logout();
    setIsAuthenticated(false);
    localStorage.removeItem("fishAuth");
    localStorage.removeItem("userId");
    localStorage.removeItem("isAuthenticated");
    setErrorMessage(error.response?.data.message ?? error.message);
    navigate("/login");
  };

  return { login, guestLogin, ready };
}

export default useCustomLogin;
