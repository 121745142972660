import { usePrivy } from "@privy-io/react-auth";
import { ethers } from "ethers";
import "../App.css";

function usePurchase() {
  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const { sendTransaction } = usePrivy();
  const ownerWallet = process.env.REACT_APP_OWNER_WALLET;

  const purchaseGold = async (ethAmount) => {
    try {
      const weiValue = ethers.utils.parseEther(ethAmount);
      const hexWeiValue = ethers.utils.hexlify(weiValue);
      const transactionRequest = {
        to: ownerWallet,
        value: hexWeiValue,
      };

      const txUiConfig = {
        header: `Send Transaction`,
        description: `Send ${ethAmount} ETH to owner`,
        buttonText: `Send`,
      };

      const txReceipt = await sendTransaction(transactionRequest, txUiConfig);

      if (txReceipt.status === 1) {
        const backendResponse = await verifyTxHashForPurchaseGold(
          txReceipt.transactionHash
        );

        if (backendResponse.success) {
          return {
            success: true,
            message: "Purchase gold successful",
            data: backendResponse.data,
          };
        } else {
          return {
            success: false,
            message: "Verify transaction failed",
          };
        }
      } else {
        console.error("Transaction failed:", txReceipt);
        return { success: false, message: "Transaction failed on blockchain" };
      }
    } catch (error) {
      console.error("Lỗi khi gửi transaction:", error);
      return { success: false, message: "Send Transaction failed" };
    }
  };

  const verifyTxHashForPurchaseGold = async (txHash) => {
    try {
      const response = await fetch(urlEndpoint + "/trading/purchase-gold", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("fishAuth")}`, // Gửi JWT token trong header
        },
        body: JSON.stringify({ transactionHash: txHash }),
      });
      const data = await response.json();
      console.log("Transaction Info:", data);

      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Lỗi khi gửi transactionHash về backend:", error);
      return { success: false, message: error.message };
    }
  };

  return { purchaseGold };
}

export default usePurchase;
